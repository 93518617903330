import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHab3 IpCamera Binding Alarmserver",
  "path": "/Frequently_Asked_Question/OpenHab3_IpCamera_Alarmserver/",
  "dateChanged": "2022-12-13",
  "author": "Mike Polinowski",
  "excerpt": "Using the OpenHab IPCamera Binding to connect your camera.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='OpenHab3 IpCamera Binding Alarmserver' dateChanged='2022-12-13' author='Mike Polinowski' tag='INSTAR IP Camera' description='Using the OpenHab IPCamera Binding to connect your camera.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/OpenHab3_IpCamera_Alarmserver/' locationFR='/fr/Frequently_Asked_Question/OpenHab3_IpCamera_Alarmserver/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "openhab3-ipcamera-binding-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab3-ipcamera-binding-alarmserver",
        "aria-label": "openhab3 ipcamera binding alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHab3 IpCamera Binding Alarmserver`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I am using OpenHab in Docker and tried to add my camera using the `}<a parentName="p" {...{
        "href": "https://openhab-next.netlify.app/addons/bindings/ipcamera/"
      }}>{`ipcamera binding`}</a>{`. But I am unable to access the video stream or snapshot. Even though the binding shows that my camera is connected.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: In the previous FAQ we showed `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/OpenHab3_IpCamera_Binding/"
      }}>{`how to setup the IPCamera Binding in OpenHab3`}</a>{` and add your camera's live video to the OpenHab dashboard using a clickable widget.`}</p>
    <p>{`But the binding offers a lot more functionality beside just handling the live video. The following steps will help you to set up the `}<strong parentName="p">{`Motion & Object Detection`}</strong>{` and use OpenHab as your camera's `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Alarmserver_Testing/"
      }}>{`Alarmserver`}</a>{`. When you add your camera to OpenHab using the binding this will automatically configure the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`Alarmserver Settings`}</a>{` in your camera to contact OpenHab if the camera's alarm is triggered:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c5e883d244bcaa515e422dc4ba65eb31/53ac9/OpenHab3_WQHD_Cameras_Alarmserver_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACc0lEQVQoz22S60/TYBSHR4RtyRwTdzUweHtZR1sSDJNdaK1u3YXu2r63tmtxpEhCxPgF/eCCMfEfIX7i3zTdYGjiLyfn25PznJMTkeUDSZKXxTHgtTZW3NsqujlG354KflXdedU4BwAIQqnZbAIAWJaNyItIkiRKUpln4fmN9/Pemd/Z89+rot/vZr/u+7P5y2S+kCmCYim79aqQ2YlIjymXy0eHhzPfsxCmjksdh9ouoTahNsYY2a6m6cmNdDKWS6xnEuvZZDS3miwKglCrVoPgglJq2zY0TUinxPGJ42F76n4IWu1hKpZNxfOpeCEZzT/fyEYenMUFXKsFQUAItakNITJNODLJ0KL9CRlBu9nsbKd3c6liKp7fjOWT0fyTtiAIjUbj8vISIQQhRAgNBgPtraqqinLSUNWwFVI7uc3txLP0RmQzvrb1oC3L8r64fyDLw8HQdV1CCMZ4MpmMFjGMPsbondYMtWPZQrp4XKmVQDmcvDSXZZkBbL3a8LwppZQQgh5jmmZwcdHVT+NrLwqZ3Z5hIIx8342IoijLsiiG5jzHq4rmn/m2vbjwwh9CaE3Mj2dOe9R7FknUG8qXm88nqjYcj1dwSLMM16ifeJ5HCIF/xTLN6dRvnQ6T0a2u0Rubg55hHL2pPRxsCXMcpyiK53khYFkIoXD5hf+Z75/2enuLxwrfi2N5nv8PPPWmGCNoWYQQh2ICJ5Bgy3H0TgcAwKwCmFB7mVCbZVVVdV0XY0zCoYjOPuGr2yvq/zCR2e7shjBglp0B/8A8z1cqlW6322q1dF3XW832yGnZ11TvX2vv27X6HsPyfIkvCSzHc3zpD00RxcqTYtdIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5e883d244bcaa515e422dc4ba65eb31/e4706/OpenHab3_WQHD_Cameras_Alarmserver_01.avif 230w", "/en/static/c5e883d244bcaa515e422dc4ba65eb31/d1af7/OpenHab3_WQHD_Cameras_Alarmserver_01.avif 460w", "/en/static/c5e883d244bcaa515e422dc4ba65eb31/7f308/OpenHab3_WQHD_Cameras_Alarmserver_01.avif 920w", "/en/static/c5e883d244bcaa515e422dc4ba65eb31/b60b2/OpenHab3_WQHD_Cameras_Alarmserver_01.avif 1287w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c5e883d244bcaa515e422dc4ba65eb31/a0b58/OpenHab3_WQHD_Cameras_Alarmserver_01.webp 230w", "/en/static/c5e883d244bcaa515e422dc4ba65eb31/bc10c/OpenHab3_WQHD_Cameras_Alarmserver_01.webp 460w", "/en/static/c5e883d244bcaa515e422dc4ba65eb31/966d8/OpenHab3_WQHD_Cameras_Alarmserver_01.webp 920w", "/en/static/c5e883d244bcaa515e422dc4ba65eb31/211bf/OpenHab3_WQHD_Cameras_Alarmserver_01.webp 1287w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c5e883d244bcaa515e422dc4ba65eb31/81c8e/OpenHab3_WQHD_Cameras_Alarmserver_01.png 230w", "/en/static/c5e883d244bcaa515e422dc4ba65eb31/08a84/OpenHab3_WQHD_Cameras_Alarmserver_01.png 460w", "/en/static/c5e883d244bcaa515e422dc4ba65eb31/c0255/OpenHab3_WQHD_Cameras_Alarmserver_01.png 920w", "/en/static/c5e883d244bcaa515e422dc4ba65eb31/53ac9/OpenHab3_WQHD_Cameras_Alarmserver_01.png 1287w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c5e883d244bcaa515e422dc4ba65eb31/c0255/OpenHab3_WQHD_Cameras_Alarmserver_01.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And can activate the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Actions/"
      }}>{`Object Detection under Alarm Actions`}</a>{`. Now every time an alarm is triggered OpenHab will receive both the information what triggered the alarm and what object if any was detected in front of the camera (person, car or animal) and it is up to you to create different rules for each case according to your needs:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/3f8cf/OpenHab3_WQHD_Cameras_Alarmserver_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB6UlEQVQoz12S3WvaUBiHczXWNag1sTWJpk0/YhIj6yhjo6ZOE02caJLzmUS31tFSWClohd7tsrtzVcb+4KFxG+zhcOC9eHjf33sOY5pmdY2mVSx479zOG+Pv9cv/z/nnp/bt3EL3qqq27FZZLivKAZOahmFUKmpvNBk+/qLT52i2jGeLaDqn02f6sKAPCzL5MXpcdpNpkZfLglLkJHFPZow/aJrWsRs46IFBd0jC8fhqdPkFBj0C+ijohf0uiUetppPfLuZeCTtbAseKm866rmua5rpeHCcIEwiC4NNdcPMNE0IoxYQAhNHdU+tjVGD3+Ewpz0p5VmR0Xf8re543HA4xxgBCgkBEYBRFlFIIIQhD1/7QqFslXuFYMc+KfEZajW2aZjq267pxHCOEAACE0jhJ8JqVDIAfhnbTEXbk7Mtd9gWX2SpsOq8XVvE8L0kShCCEMFwDVqxKCCAluG13uG1hv3RinV8YWo1JzU3mjksIgRAihFZhowiBAF/PwGw5IVc/B35oNcuy2vcHvu8Tiv/Juq7XzNq7t++rldqJoquKcXxgqIqhHr1Wj8+0ffNUMUu83LSdm6/XdevC9Twm/STpax0eHhlaVSkfcZliIScVcmJ672YFPidxWSmfKXa6btt1HLdz+ubsNxupkUJasd9sAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/e4706/OpenHab3_WQHD_Cameras_Alarmserver_02.avif 230w", "/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/d1af7/OpenHab3_WQHD_Cameras_Alarmserver_02.avif 460w", "/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/7f308/OpenHab3_WQHD_Cameras_Alarmserver_02.avif 920w", "/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/f1ddf/OpenHab3_WQHD_Cameras_Alarmserver_02.avif 1293w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/a0b58/OpenHab3_WQHD_Cameras_Alarmserver_02.webp 230w", "/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/bc10c/OpenHab3_WQHD_Cameras_Alarmserver_02.webp 460w", "/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/966d8/OpenHab3_WQHD_Cameras_Alarmserver_02.webp 920w", "/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/dfa64/OpenHab3_WQHD_Cameras_Alarmserver_02.webp 1293w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/81c8e/OpenHab3_WQHD_Cameras_Alarmserver_02.png 230w", "/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/08a84/OpenHab3_WQHD_Cameras_Alarmserver_02.png 460w", "/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/c0255/OpenHab3_WQHD_Cameras_Alarmserver_02.png 920w", "/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/3f8cf/OpenHab3_WQHD_Cameras_Alarmserver_02.png 1293w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ef0a35c48fa7227a27ab9b224ec8cbd8/c0255/OpenHab3_WQHD_Cameras_Alarmserver_02.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the camera `}<strong parentName="p">{`Thing`}</strong>{` you added with the IpCamera Binding and bind each alarm trigger to an item of your choice:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4771541b6022e19b727b94f1b7fceeec/f2763/OpenHab3_WQHD_Cameras_Alarmserver_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABcUlEQVQoz12R22rbQBCG9QSxV9qV7Ghmdvag0+oUmRCcxLET2uQBCoVCr0qv+/63RbJdSoa5/D/mG/6oXMYXpQVwrDUzkkZEImJma+xuGIyxzEZrTaTnBHPFumAdNaFtQltWdSKllCrfZk9j3fWjs3aOEhkEWgYQIc91ETbb7RxVKhpqj1mCWZLKZLVeb1Ty8dA/v5ymaXLOac0ABIAAsw0CUtFJqYRYx0JE47c/+OUXvf9OuRarG5XK41QfD8dhHM+SkMMZPi8RL7CI4zgqv/6Ew3c8/lBUiNWNVMrXrg2d9z7P5zCwA+J/MKKWUsXxAreldXRb8G2WqrUQm1Qe7prd/UPTBCKNxKD/g3NAH6RKL5fbrrfO+aLIskwIkSRJG8Lr69t+v3fOGza02CPS5W0kpeTCxtE4jlVVOeeusAxtdzqdHh+fQmiNsc45a73WRjPPLtoodf35Eyyl7Pvh8PwyTTsztzVXf1k2iIiuUVftvyc7RGzEYiSlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4771541b6022e19b727b94f1b7fceeec/e4706/OpenHab3_WQHD_Cameras_Alarmserver_03.avif 230w", "/en/static/4771541b6022e19b727b94f1b7fceeec/d1af7/OpenHab3_WQHD_Cameras_Alarmserver_03.avif 460w", "/en/static/4771541b6022e19b727b94f1b7fceeec/7f308/OpenHab3_WQHD_Cameras_Alarmserver_03.avif 920w", "/en/static/4771541b6022e19b727b94f1b7fceeec/755d7/OpenHab3_WQHD_Cameras_Alarmserver_03.avif 1291w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4771541b6022e19b727b94f1b7fceeec/a0b58/OpenHab3_WQHD_Cameras_Alarmserver_03.webp 230w", "/en/static/4771541b6022e19b727b94f1b7fceeec/bc10c/OpenHab3_WQHD_Cameras_Alarmserver_03.webp 460w", "/en/static/4771541b6022e19b727b94f1b7fceeec/966d8/OpenHab3_WQHD_Cameras_Alarmserver_03.webp 920w", "/en/static/4771541b6022e19b727b94f1b7fceeec/b21f7/OpenHab3_WQHD_Cameras_Alarmserver_03.webp 1291w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4771541b6022e19b727b94f1b7fceeec/81c8e/OpenHab3_WQHD_Cameras_Alarmserver_03.png 230w", "/en/static/4771541b6022e19b727b94f1b7fceeec/08a84/OpenHab3_WQHD_Cameras_Alarmserver_03.png 460w", "/en/static/4771541b6022e19b727b94f1b7fceeec/c0255/OpenHab3_WQHD_Cameras_Alarmserver_03.png 920w", "/en/static/4771541b6022e19b727b94f1b7fceeec/f2763/OpenHab3_WQHD_Cameras_Alarmserver_03.png 1291w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4771541b6022e19b727b94f1b7fceeec/c0255/OpenHab3_WQHD_Cameras_Alarmserver_03.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can test the item by sending a `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Alarmserver_Testing/"
      }}>{`Test Command to trigger the Alarmserver`}</a>{` to your camera and adding the alarm trigger you want to test. For example the following command simulates a person walking into `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Areas/"
      }}>{`Motion Detection Area 1`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.120/param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`testas`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`index`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`active`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`object`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`admin`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pwd`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`instar`}</code></pre></div>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`192.168.2.120`}</code>{` => Replace this with your camera's local `}<strong parentName="li">{`IP Address`}</strong></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`admin`}</code>{` => Replace this with your camera `}<strong parentName="li">{`Admin Username`}</strong></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar`}</code>{` => Replace this with your camera `}<strong parentName="li">{`Admin Password`}</strong></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`active`}</code>{` => Alarm Trigger`}
        <ul parentName="li">
          <li parentName="ul">{`Alarm Area 1 triggered: &active=1`}</li>
          <li parentName="ul">{`Alarm Area 2 triggered: &active=2`}</li>
          <li parentName="ul">{`Alarm Area 3 triggered: &active=3`}</li>
          <li parentName="ul">{`Alarm Area 4 triggered: &active=4`}</li>
          <li parentName="ul">{`Alarm-In / PIR triggered: &active=5`}</li>
          <li parentName="ul">{`Audio Alarm triggered: &active=6`}</li>
          <li parentName="ul">{`PIR triggered & Alarm Area 1 triggered: &active=7`}</li>
          <li parentName="ul">{`PIR triggered & Alarm Area 2 triggered: &active=8`}</li>
          <li parentName="ul">{`PIR triggered & Alarm Area 3 triggered: &active=9`}</li>
          <li parentName="ul">{`PIR triggered & Alarm Area 4 triggered: &active=10`}</li>
          <li parentName="ul">{`Manual trigger (cmd=pushhostalarm): &active=99`}</li>
        </ul>
      </li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`object`}</code>{` => Detected Object`}
        <ul parentName="li">
          <li parentName="ul">{`Person: &object=1`}</li>
          <li parentName="ul">{`Vehicles: &object=2`}</li>
          <li parentName="ul">{`Person + Vehicles: &object=3`}</li>
          <li parentName="ul">{`Animals: &object=4`}</li>
          <li parentName="ul">{`Person + Animals: &object=5`}</li>
          <li parentName="ul">{`Person + Vehicles: &object=6`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Accordingly sending this command will trigger both the `}<strong parentName="p">{`General Motion Detection`}</strong>{` item in OpenHab as well as the `}<strong parentName="p">{`Human Detection`}</strong>{`. The `}<strong parentName="p">{`ON`}</strong>{` state will drop back to `}<strong parentName="p">{`OFF`}</strong>{` after a few seconds allowing you to use it as a pulse trigger for your automations:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/1fa7a66463796cb364d9d4348b0c4f51/OpenHab3_WQHD_Cameras_Alarmserver_04.gif",
        "alt": "OpenHab3 IpCamera Binding with an WQHD Camera"
      }}></img></p>
    <p>{`You can also further process alarm events by using the IPCamera bindings `}<strong parentName="p">{`Last Motion Type`}</strong>{` and `}<strong parentName="p">{`Last Event Data`}</strong>{` channels - for example use it for notification or displaying historic event data:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a86389c4a0eb7d6265c644846258800e/610c0/OpenHab3_WQHD_Cameras_Alarmserver_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABkklEQVQoz2WQTW7bMBCFdYOIEjkckuKfSOrHtCoFKJKsskpX2RSFT+FdUaCFz+OLFpZkwEU/zIbD9zAzr7DW5pynaUoptVq13rs73nul1Ol0Op/P/qHvnLPOJW8LY0yMses6a22j9aMohEAIuVwu1+uVc/6/v/j68jbNS2Osca1zzjyitbV2HIecs/dea/34aa0pljE+H1LUPBmk9Q1KKVsBAETknANwRASArU/pJqwL9/13+PEHP3/Jz581b0j5xBhTSgmhmkaP49j3wzCMOR+1NkIIpRQAEEKqqiq6l4/+7ZtZ3u3ze82AlCUiSqkoZQCcUcaFQhTbc4MxqFaKMbXHIUWng9O0rgkhiBhCDCE2WiNyl0bfRiFQSimElFJxjrt5mqZ5nruua9uWUlqWpZQyxWTMbcnbnWsEcGcbvptzzsfjMaUUQtjMKKRLA+NileEG53sBu92ym9uVsEIpJYRwzmN/UI2mlMK/3LL0DSrcAwt3trUJIQAwf5mXZbHWGWOlVHsphVz0rwfTufKprOrqLwrhT5I6KEMBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a86389c4a0eb7d6265c644846258800e/e4706/OpenHab3_WQHD_Cameras_Alarmserver_05.avif 230w", "/en/static/a86389c4a0eb7d6265c644846258800e/d1af7/OpenHab3_WQHD_Cameras_Alarmserver_05.avif 460w", "/en/static/a86389c4a0eb7d6265c644846258800e/7f308/OpenHab3_WQHD_Cameras_Alarmserver_05.avif 920w", "/en/static/a86389c4a0eb7d6265c644846258800e/42d56/OpenHab3_WQHD_Cameras_Alarmserver_05.avif 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a86389c4a0eb7d6265c644846258800e/a0b58/OpenHab3_WQHD_Cameras_Alarmserver_05.webp 230w", "/en/static/a86389c4a0eb7d6265c644846258800e/bc10c/OpenHab3_WQHD_Cameras_Alarmserver_05.webp 460w", "/en/static/a86389c4a0eb7d6265c644846258800e/966d8/OpenHab3_WQHD_Cameras_Alarmserver_05.webp 920w", "/en/static/a86389c4a0eb7d6265c644846258800e/a8acc/OpenHab3_WQHD_Cameras_Alarmserver_05.webp 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a86389c4a0eb7d6265c644846258800e/81c8e/OpenHab3_WQHD_Cameras_Alarmserver_05.png 230w", "/en/static/a86389c4a0eb7d6265c644846258800e/08a84/OpenHab3_WQHD_Cameras_Alarmserver_05.png 460w", "/en/static/a86389c4a0eb7d6265c644846258800e/c0255/OpenHab3_WQHD_Cameras_Alarmserver_05.png 920w", "/en/static/a86389c4a0eb7d6265c644846258800e/610c0/OpenHab3_WQHD_Cameras_Alarmserver_05.png 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a86389c4a0eb7d6265c644846258800e/c0255/OpenHab3_WQHD_Cameras_Alarmserver_05.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/86a1e/OpenHab3_WQHD_Cameras_Alarmserver_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABi0lEQVQoz1VSy47UMBDMH0zsOH603x0nk2QyDwFZJCTmDOwi7iyfwN7Z35ovhMTsaKZUB7ta5eq2XSA2bdsOwxB86EKI92hSen7+9fj4FO5LDaI3rkDEruvGcbTWOufCDRCRMXa5XF5f/1RVhYjXUowBjCvefXjohxG0dSHmgn+Dcy6lNM/z6XSKMTrnsp7N2rji/b4/9Ng5hVbSagFbUL+RUUpX8arUy7aqSlIV6ftL+PZbfXmB809abiilnHMADaCVAufDOIzbba+1ySKAXk8khLBie/6RPj35j1/t6UxJSQgBACkVYzXngnMBxkkp8zozt7Mpq2JosW9C8jpaoOuBSoH3wTkPoKVU2O+tdVIqpSCzrjmlpCyr4nA4Ho7Htu1CCJTSsixjjEPfI6KUinOxTFnza6yUKps3JS2madpNU0opmwkhxnobsK65vIEQmUIsbddr27TIj35rVtrE7Y4vCUvi7bRcCHCa1ey/uVlxl6zNwzzv1m+jjbmOqiSA0cPnvVCSEvLvwv4C2n9OiBxFcoUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/e4706/OpenHab3_WQHD_Cameras_Alarmserver_06.avif 230w", "/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/d1af7/OpenHab3_WQHD_Cameras_Alarmserver_06.avif 460w", "/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/7f308/OpenHab3_WQHD_Cameras_Alarmserver_06.avif 920w", "/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/6f791/OpenHab3_WQHD_Cameras_Alarmserver_06.avif 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/a0b58/OpenHab3_WQHD_Cameras_Alarmserver_06.webp 230w", "/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/bc10c/OpenHab3_WQHD_Cameras_Alarmserver_06.webp 460w", "/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/966d8/OpenHab3_WQHD_Cameras_Alarmserver_06.webp 920w", "/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/0b716/OpenHab3_WQHD_Cameras_Alarmserver_06.webp 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/81c8e/OpenHab3_WQHD_Cameras_Alarmserver_06.png 230w", "/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/08a84/OpenHab3_WQHD_Cameras_Alarmserver_06.png 460w", "/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/c0255/OpenHab3_WQHD_Cameras_Alarmserver_06.png 920w", "/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/86a1e/OpenHab3_WQHD_Cameras_Alarmserver_06.png 1296w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eb4bd0b78ea835752c5cd76afc9d4a7a/c0255/OpenHab3_WQHD_Cameras_Alarmserver_06.png",
              "alt": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "title": "OpenHab3 IpCamera Binding with an WQHD Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      